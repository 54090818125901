import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
import { CoffeePointsProvider } from "./context/CoffeePointsContext";
import "./App.css";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Navigation from "./components/layout/Navigation";
import Home from "./components/pages/Home";
import Auth from "./components/pages/Auth";
import Cart from "./components/pages/Cart";
import ProductDetail from "./components/pages/ProductDetail";
import Payment from "./components/pages/Payment";
import Profile from "./components/pages/Profile";
import Settings from "./components/pages/Settings";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <CoffeePointsProvider>
          <Router>
            <div className="app">
              <Header />
              <main>
                <Routes>
                  <Route path="/auth" element={<Auth />} />
                  <Route
                    path="/"
                    element={
                      <ProtectedRoute>
                        <Home />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      <ProtectedRoute>
                        <Routes>
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/settings" element={<Settings />} />
                          <Route path="/cart" element={<Cart />} />
                          <Route
                            path="/product/:id"
                            element={<ProductDetail />}
                          />
                          <Route path="/payment" element={<Payment />} />
                        </Routes>
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </main>
              <Navigation />
            </div>
          </Router>
        </CoffeePointsProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
