import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useCart } from "../../context/CartContext";
import { BsCartX } from "react-icons/bs";

const Cart = () => {
  const navigate = useNavigate();
  const { items, removeFromCart, updateQuantity } = useCart();

  const total = items.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  const handleCheckout = () => {
    navigate("/payment");
  };

  const handleViewProducts = () => {
    navigate("/");
  };

  return (
    <Container>
      <Title>Sepetim</Title>
      {items.length === 0 ? (
        <EmptyCartContainer>
          <EmptyCartIcon>
            <BsCartX size={64} />
          </EmptyCartIcon>
          <EmptyCart>Sepetiniz boş</EmptyCart>
          <EmptyCartDescription>
            Sepetinizde ürün bulunmamaktadır. Hemen alışverişe başlayın!
          </EmptyCartDescription>
          <ViewProductsButton onClick={handleViewProducts}>
            Ürünleri Gör
          </ViewProductsButton>
        </EmptyCartContainer>
      ) : (
        <>
          <CartItems>
            {items.map((item) => (
              <CartItem key={item.id}>
                <ItemInfo>
                  <ItemName>{item.name}</ItemName>
                  <ItemPrice>{item.price} TL</ItemPrice>
                </ItemInfo>
                <QuantityControl>
                  <QuantityButton
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                  >
                    -
                  </QuantityButton>
                  <QuantityDisplay>{item.quantity}</QuantityDisplay>
                  <QuantityButton
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  >
                    +
                  </QuantityButton>
                </QuantityControl>
                <RemoveButton onClick={() => removeFromCart(item.id)}>
                  Kaldır
                </RemoveButton>
              </CartItem>
            ))}
          </CartItems>
          <Total>
            <span>Toplam:</span>
            <span>{total} TL</span>
          </Total>
          <CheckoutButton onClick={handleCheckout}>Ödemeye Geç</CheckoutButton>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: ${theme.spacing.xl};
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.xl};
`;

const EmptyCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.xl} 0;
  text-align: center;
`;

const EmptyCartIcon = styled.div`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.lg};
  opacity: 0.7;
`;

const EmptyCart = styled.h2`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSize.large};
  margin-bottom: ${theme.spacing.md};
`;

const EmptyCartDescription = styled.p`
  color: ${theme.colors.gray};
  margin-bottom: ${theme.spacing.xl};
`;

const ViewProductsButton = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  font-size: ${theme.fontSize.medium};
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${theme.colors.primary}dd;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.md};
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

const ItemName = styled.span`
  color: ${theme.colors.primary};
  font-weight: bold;
`;

const ItemPrice = styled.span`
  color: ${theme.colors.black};
`;

const QuantityControl = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  margin-right: ${theme.spacing.md};
`;

const QuantityButton = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;
  width: 24px;
  height: 24px;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
  }
`;

const QuantityDisplay = styled.span`
  min-width: 24px;
  text-align: center;
  font-weight: bold;
`;

const RemoveButton = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${theme.spacing.xl} 0;
  padding: ${theme.spacing.md};
  background: ${theme.colors.secondary};
  border-radius: ${theme.borderRadius.medium};
  font-weight: bold;
`;

const CheckoutButton = styled.button`
  width: 100%;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;
  padding: ${theme.spacing.md};
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  font-size: ${theme.fontSize.medium};

  &:hover {
    background: ${theme.colors.primary}dd;
  }
`;

export default Cart;
