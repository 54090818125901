import { createContext, useContext, useState } from "react";

const CoffeePointsContext = createContext();

export const CoffeePointsProvider = ({ children }) => {
  const [coffeePoints, setCoffeePoints] = useState(0);

  return (
    <CoffeePointsContext.Provider value={{ coffeePoints, setCoffeePoints }}>
      {children}
    </CoffeePointsContext.Provider>
  );
};

export const useCoffeePoints = () => {
  const context = useContext(CoffeePointsContext);
  if (!context) {
    throw new Error(
      "useCoffeePoints must be used within a CoffeePointsProvider"
    );
  }
  return context;
};
