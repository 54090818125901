import styled from "styled-components";
import { theme } from "../../styles/theme";

const Footer = () => {
  return (
    <FooterContainer>
      <p>© 2024 Klaus Coffee. Tüm hakları saklıdır.</p>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  text-align: center;
  padding: ${theme.spacing.lg};
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
`;

export default Footer;
