import styled from "styled-components";
import { theme } from "../../styles/theme";

const CoffeePoint = ({ filled }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={filled ? theme.colors.primary : "none"}
    stroke={theme.colors.primary}
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 8h-1V6c0-1.1-.9-2-2-2H5C3.9 4 3 4.9 3 6v8c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-2h1c1.65 0 3-1.35 3-3s-1.35-3-3-3zM5 6h10v2H5V6z" />
  </svg>
);

export default CoffeePoint;
