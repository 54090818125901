import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useCart } from "../../context/CartContext";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { items } = useCart();

  return (
    <Nav>
      <NavItem active={location.pathname === "/"} onClick={() => navigate("/")}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          <polyline points="9 22 9 12 15 12 15 22" />
        </svg>
        <span>Ana Sayfa</span>
      </NavItem>

      <NavItem
        active={location.pathname === "/cart"}
        onClick={() => navigate("/cart")}
      >
        <CartWrapper>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <circle cx="9" cy="21" r="1" />
            <circle cx="20" cy="21" r="1" />
            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
          </svg>
          {items.length > 0 && <CartBadge>{items.length}</CartBadge>}
        </CartWrapper>
        <span>Sepetim</span>
      </NavItem>

      <NavItem
        active={location.pathname === "/profile"}
        onClick={() => navigate("/profile")}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
          <circle cx="12" cy="7" r="4" />
        </svg>
        <span>Profilim</span>
      </NavItem>
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: ${theme.colors.white};
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.black};
  cursor: pointer;
  padding: 8px;
  transition: color 0.2s;

  svg {
    margin-bottom: 4px;
  }

  span {
    font-size: ${theme.fontSize.small};
  }

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const CartWrapper = styled.div`
  position: relative;
`;

const CartBadge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background: ${theme.colors.primary};
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export default Navigation;
