import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useAuth } from "../../context/AuthContext";

const Header = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  return (
    <HeaderContainer>
      <Logo onClick={() => navigate("/")}>Klaus Coffee</Logo>
      <Nav>
        {user ? (
          <>
            <NavItem onClick={logout}>Çıkış Yap</NavItem>
          </>
        ) : (
          <NavItem onClick={() => navigate("/auth")}>Giriş Yap</NavItem>
        )}
      </Nav>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  background: ${theme.colors.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  cursor: pointer;
`;

const Nav = styled.nav`
  display: flex;
  gap: ${theme.spacing.md};
`;

const NavItem = styled.div`
  color: ${theme.colors.primary};
  cursor: pointer;
  padding: ${theme.spacing.sm};

  &:hover {
    text-decoration: underline;
  }
`;

export default Header;
