import styled from "styled-components";
import { theme } from "../../styles/theme";

export const SmallCup = ({ color = theme.colors.primary }) => (
  <CupSvg viewBox="0 0 24 24" width="24" height="24" color={color}>
    <path
      d="M4 7h12c1.1 0 2 .9 2 2v2.5c0 3-2.5 5.5-5.5 5.5h-5C4.5 17 2 14.5 2 11.5V9c0-1.1.9-2 2-2z"
      fill="currentColor"
      opacity="0.2"
    />
    <path
      d="M4 7h12c1.1 0 2 .9 2 2v2.5c0 3-2.5 5.5-5.5 5.5h-5C4.5 17 2 14.5 2 11.5V9c0-1.1.9-2 2-2"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9h2c1.1 0 2 .9 2 2v0c0 1.1-.9 2-2 2h-2"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </CupSvg>
);

export const LargeCup = ({ color = theme.colors.primary }) => (
  <CupSvg viewBox="0 0 24 24" width="32" height="32" color={color}>
    <path
      d="M3 4h14c1.1 0 2 .9 2 2v6c0 4.4-3.6 8-8 8h-2c-4.4 0-8-3.6-8-8V6c0-1.1.9-2 2-2z"
      fill="currentColor"
      opacity="0.2"
    />
    <path
      d="M3 4h14c1.1 0 2 .9 2 2v6c0 4.4-3.6 8-8 8h-2c-4.4 0-8-3.6-8-8V6c0-1.1.9-2 2-2"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8h2c1.1 0 2 .9 2 2v0c0 1.1-.9 2-2 2h-2"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 1v3M5 2v2M13 2v2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </CupSvg>
);

const CupSvg = styled.svg`
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-1px);
  }
`;
