import { useCart } from "../../context/CartContext";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useState } from "react";
import { useCoffeePoints } from "../../context/CoffeePointsContext";

const Profile = () => {
  const { orderHistory = [] } = useCart();
  const { coffeePoints } = useCoffeePoints();
  const [activeTab, setActiveTab] = useState("orders");

  const sortedOrders = [...orderHistory].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const totalSpent = orderHistory.reduce(
    (total, order) =>
      total + (typeof order.total === "number" ? order.total : 0),
    0
  );

  return (
    <PageContainer>
      <ProfileHeader>
        <WelcomeSection>
          <Avatar>👤</Avatar>
          <UserInfo>
            <WelcomeText>Hoş Geldiniz</WelcomeText>
            <StatsGrid>
              <StatBox>
                <StatValue>{orderHistory?.length || 0}</StatValue>
                <StatLabel>Sipariş</StatLabel>
              </StatBox>
              <StatBox>
                <StatValue>{coffeePoints}/5</StatValue>
                <StatLabel>Kahve Puanı</StatLabel>
              </StatBox>
            </StatsGrid>
          </UserInfo>
        </WelcomeSection>
      </ProfileHeader>

      <TabsContainer>
        <TabButton
          active={activeTab === "orders"}
          onClick={() => setActiveTab("orders")}
        >
          Siparişlerim
        </TabButton>
        <TabButton
          active={activeTab === "favorites"}
          onClick={() => setActiveTab("favorites")}
        >
          Favorilerim
        </TabButton>
      </TabsContainer>

      <ContentSection>
        {activeTab === "orders" && (
          <>
            {!sortedOrders || sortedOrders.length === 0 ? (
              <EmptyState>
                <EmptyIcon>☕</EmptyIcon>
                <EmptyTitle>Henüz Siparişiniz Bulunmuyor</EmptyTitle>
                <EmptyText>
                  İlk siparişinizi vererek kahve yolculuğunuza başlayın!
                </EmptyText>
              </EmptyState>
            ) : (
              <OrdersList>
                {sortedOrders.map((order) => (
                  <OrderCard key={order.id}>
                    <OrderCardHeader>
                      <OrderMetadata>
                        <OrderDate>
                          {new Date(order.date).toLocaleDateString("tr-TR", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </OrderDate>
                        <OrderNumber>
                          #{String(order.id).slice(-6).padStart(6, "0")}
                        </OrderNumber>
                      </OrderMetadata>
                      <StatusBadge>Tamamlandı</StatusBadge>
                    </OrderCardHeader>

                    <OrderDetails>
                      {order.items &&
                        order.items.map((item, idx) => (
                          <OrderItem key={idx}>
                            <ProductInfo>
                              <ProductName>
                                {item.name}
                                {item.selectedSize && (
                                  <SizeInfo>
                                    {item.sizes[item.selectedSize].name}
                                  </SizeInfo>
                                )}
                              </ProductName>
                              <ProductQuantity>
                                {item.quantity} adet × {item.price} TL
                              </ProductQuantity>
                            </ProductInfo>
                            <ProductPrice>
                              {(item.price * item.quantity).toFixed(2)} TL
                            </ProductPrice>
                          </OrderItem>
                        ))}
                    </OrderDetails>

                    <OrderCardFooter>
                      <PaymentInfo>
                        Kart ile ödendi (*{order.cardNumber})
                      </PaymentInfo>
                      <TotalAmount>
                        {typeof order.total === "number"
                          ? `${order.total.toFixed(2)} TL`
                          : "0.00 TL"}
                      </TotalAmount>
                    </OrderCardFooter>
                  </OrderCard>
                ))}
              </OrdersList>
            )}
          </>
        )}
        {activeTab === "favorites" && (
          <EmptyState>
            <EmptyIcon>⭐</EmptyIcon>
            <EmptyTitle>Henüz Favoriniz Bulunmuyor</EmptyTitle>
            <EmptyText>
              Favori kahvelerinizi ekleyerek daha hızlı sipariş verebilirsiniz!
            </EmptyText>
          </EmptyState>
        )}
      </ContentSection>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const ProfileHeader = styled.div`
  background: ${theme.colors.primary};
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  color: white;

  @media (max-width: 768px) {
    padding: 16px;
    border-radius: 12px;
  }
`;

const WelcomeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`;

const UserInfo = styled.div`
  flex: 1;
`;

const WelcomeText = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
`;

const StatsGrid = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const StatBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 12px 24px;
  border-radius: 12px;
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const StatLabel = styled.div`
  font-size: 14px;
  opacity: 0.8;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  padding: 8px;
  background: ${theme.colors.lightBackground};
  border-radius: 12px;

  @media (max-width: 768px) {
    overflow-x: auto;
    padding: 8px;
  }
`;

const TabButton = styled.button`
  padding: 12px 24px;
  border: none;
  background: ${(props) => (props.active ? theme.colors.primary : "white")};
  color: ${(props) => (props.active ? "white" : theme.colors.darkGray)};
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
  font-size: 15px;
  box-shadow: ${(props) =>
    props.active
      ? "0 4px 12px rgba(0, 0, 0, 0.15)"
      : "0 2px 4px rgba(0, 0, 0, 0.05)"};
  border: 2px solid
    ${(props) => (props.active ? theme.colors.primary : theme.colors.lightGray)};

  &:hover {
    background: ${(props) =>
      props.active ? theme.colors.primary : theme.colors.lightBackground};
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ContentSection = styled.div`
  margin-top: 24px;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 48px 24px;
`;

const EmptyIcon = styled.div`
  font-size: 48px;
  margin-bottom: 16px;
`;

const EmptyTitle = styled.h2`
  font-size: 20px;
  color: ${theme.colors.primary};
  margin-bottom: 8px;
`;

const EmptyText = styled.p`
  color: ${theme.colors.gray};
`;

const OrdersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OrderCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border: 1px solid ${theme.colors.lightGray};
  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  }
`;

const OrderCardHeader = styled.div`
  padding: 16px;
  background: ${theme.colors.primary}05;
  border-bottom: 1px solid ${theme.colors.lightGray};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const OrderMetadata = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const OrderDate = styled.span`
  color: ${theme.colors.darkGray};
  font-weight: 500;
`;

const OrderNumber = styled.span`
  color: ${theme.colors.darkGray};
  font-size: 14px;
  font-weight: 500;
`;

const StatusBadge = styled.span`
  background: ${theme.colors.success}15;
  color: ${theme.colors.success};
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid ${theme.colors.success}20;
`;

const OrderDetails = styled.div`
  padding: 16px;
`;

const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.lightGray};
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ProductName = styled.span`
  font-weight: 500;
`;

const ProductQuantity = styled.span`
  font-size: 14px;
  color: ${theme.colors.darkGray};
  font-weight: 500;
`;

const ProductPrice = styled.span`
  font-weight: 500;
`;

const OrderCardFooter = styled.div`
  padding: 16px;
  background: ${theme.colors.primary}05;
  border-top: 1px solid ${theme.colors.lightGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentInfo = styled.span`
  color: ${theme.colors.darkGray};
  font-size: 14px;
  font-weight: 500;
`;

const TotalAmount = styled.span`
  color: ${theme.colors.primary};
  font-weight: 600;
  font-size: 18px;
`;

const SizeInfo = styled.span`
  font-size: 12px;
  color: ${theme.colors.primary};
  margin-left: 8px;
  background: ${theme.colors.primary}10;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
`;

export default Profile;
