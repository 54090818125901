import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";

const Settings = () => {
  return (
    <Container>
      <Title>Ayarlar</Title>
      <Content>
        {/* Ayarlar sayfası içeriği buraya gelecek */}
        <p>Ayarlar sayfası yapım aşamasında...</p>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: ${theme.spacing.xl};
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.lg};
`;

const Content = styled.div`
  background: ${theme.colors.white};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export default Settings;
