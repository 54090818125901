import { theme } from "../../styles/theme";

const LargeCoffeeIcon = ({ fillPercentage = 0 }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4h16v7a8 8 0 0 1-8 8 8 8 0 0 1-8-8V4Z"
      fill={`${theme.colors.primary}${Math.round((fillPercentage * 255) / 100)
        .toString(16)
        .padStart(2, "0")}`}
      stroke={theme.colors.primary}
      strokeWidth="1.5"
    />
    <path
      d="M20 8h2a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-2"
      stroke={theme.colors.primary}
      strokeWidth="1.5"
    />
    <path
      d="M7 21h10"
      stroke={theme.colors.primary}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default LargeCoffeeIcon;
