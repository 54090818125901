import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme";
import { useAuth } from "../../context/AuthContext";

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    const phoneNumber = e.target.phoneNumber.value;
    const password = e.target.password.value;

    if (isLogin) {
      const success = login(phoneNumber, password);
      if (success) {
        navigate("/");
      } else {
        setError("Telefon numarası veya şifre hatalı!");
      }
    } else {
      const firstName = e.target.firstName.value;
      const lastName = e.target.lastName.value;
      setError("Şu anda sadece demo hesabı ile giriş yapılabilir.");
    }
  };

  return (
    <Container>
      <AuthCard>
        <Title>{isLogin ? "Giriş Yap" : "Kayıt Ol"}</Title>
        <Form onSubmit={handleSubmit}>
          {!isLogin && (
            <>
              <Input name="firstName" type="text" placeholder="Ad" required />
              <Input name="lastName" type="text" placeholder="Soyad" required />
            </>
          )}
          <Input
            name="phoneNumber"
            type="tel"
            placeholder="Telefon Numarası"
            pattern="[0-9]{10}"
            title="Lütfen 10 haneli telefon numaranızı giriniz (Başında 0 olmadan)"
            required
          />
          <Input name="password" type="password" placeholder="Şifre" required />
          {error && <ErrorText>{error}</ErrorText>}
          <Button type="submit">{isLogin ? "Giriş Yap" : "Kayıt Ol"}</Button>
        </Form>
        <ToggleText onClick={() => setIsLogin(!isLogin)}>
          {isLogin
            ? "Hesabınız yok mu? Kayıt olun"
            : "Zaten üye misiniz? Giriş yapın"}
        </ToggleText>
      </AuthCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: ${theme.spacing.xl};
`;

const AuthCard = styled.div`
  background: ${theme.colors.white};
  padding: ${theme.spacing.xl};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: ${theme.colors.primary};
  text-align: center;
  margin-bottom: ${theme.spacing.lg};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;

const Input = styled.input`
  padding: ${theme.spacing.md};
  border: 1px solid ${theme.colors.gray};
  border-radius: ${theme.borderRadius.small};
`;

const Button = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  padding: ${theme.spacing.md};
  border: none;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;

  &:hover {
    background: ${theme.colors.primary}dd;
  }
`;

const ToggleText = styled.p`
  text-align: center;
  margin-top: ${theme.spacing.md};
  color: ${theme.colors.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorText = styled.p`
  color: red;
  text-align: center;
  margin: ${theme.spacing.sm} 0;
  font-size: 0.9em;
`;

export default Auth;
