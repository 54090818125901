const ColdMilkyCoffee = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3V7M12 7L9 5M12 7L15 5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M5 10C5 8.89543 5.89543 8 7 8H17C18.1046 8 19 8.89543 19 10V16C19 18.2091 17.2091 20 15 20H9C6.79086 20 5 18.2091 5 16V10Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M19 13H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8 8V6C8 5.44772 8.44772 5 9 5H13C13.5523 5 14 5.44772 14 6V8"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default ColdMilkyCoffee;
