import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useCoffeePoints } from "../../context/CoffeePointsContext";
import { useCart } from "../../context/CartContext";

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setCoffeePoints } = useCoffeePoints();
  const { items, completeOrder } = useCart();
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");

  const cartTotal = items.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );
  const product = location.state?.product;
  const total = product ? product.finalPrice : cartTotal;

  useEffect(() => {
    if (!product && items.length === 0) {
      navigate("/");
    }
  }, [product, items, navigate]);

  const handlePaymentSuccess = (e) => {
    e.preventDefault();

    if (product) {
      completeOrder({
        paymentDetails: {
          cardNumber: cardNumber.slice(-4),
          date: new Date(),
        },
        items: [
          {
            ...product,
            quantity: 1,
            price: product.finalPrice,
          },
        ],
      });
    } else {
      completeOrder({
        paymentDetails: {
          cardNumber: cardNumber.slice(-4),
          date: new Date(),
        },
      });
    }

    setCoffeePoints((prevPoints) => {
      const newPoints = prevPoints + 1;
      if (newPoints >= 5) {
        alert("Tebrikler! Bir kahve kazandınız!");
        return 0;
      }
      return newPoints;
    });

    alert("Ödeme başarılı! Siparişiniz hazırlanıyor.");
    navigate("/");
  };

  return (
    <Container>
      <Title>Ödeme Sayfası</Title>
      <OrderSummarySection>
        <h3>Sipariş Özeti</h3>
        {product ? (
          <ProductInfo>
            <ProductName>
              {product.name}
              <SizeInfo>{product.sizes[product.selectedSize].name}</SizeInfo>
            </ProductName>
            <ProductPrice>{product.finalPrice} TL</ProductPrice>
          </ProductInfo>
        ) : (
          <ProductInfo>
            <ProductName>Sepet Toplamı</ProductName>
            <ProductPrice>{cartTotal} TL</ProductPrice>
          </ProductInfo>
        )}
      </OrderSummarySection>
      <Form onSubmit={handlePaymentSuccess}>
        <InputGroup>
          <Label>Kart Numarası</Label>
          <Input
            type="text"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="1234 5678 9012 3456"
            maxLength="19"
            required
          />
        </InputGroup>
        <Row>
          <InputGroup>
            <Label>Son Kullanma Tarihi</Label>
            <Input
              type="text"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              placeholder="MM/YY"
              maxLength="5"
              required
            />
          </InputGroup>
          <InputGroup>
            <Label>CVV</Label>
            <Input
              type="text"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              placeholder="123"
              maxLength="3"
              required
            />
          </InputGroup>
        </Row>
        <PayButton type="submit">Ödemeyi Tamamla</PayButton>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: ${theme.spacing.xl};
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: ${theme.spacing.lg};
    max-width: 100%;
  }

  @media (max-width: 480px) {
    padding: ${theme.spacing.md};
  }
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.xl};
  font-size: ${theme.fontSize.xlarge};

  @media (max-width: 480px) {
    font-size: ${theme.fontSize.large};
    margin-bottom: ${theme.spacing.lg};
  }
`;

const OrderSummarySection = styled.div`
  background: ${theme.colors.gray};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius.medium};
  margin-bottom: ${theme.spacing.xl};

  @media (max-width: 480px) {
    padding: ${theme.spacing.md};
    margin-bottom: ${theme.spacing.lg};
  }

  h3 {
    color: ${theme.colors.primary};
    margin-bottom: ${theme.spacing.md};
    font-size: ${theme.fontSize.large};

    @media (max-width: 480px) {
      font-size: ${theme.fontSize.medium};
    }
  }
`;

const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.md} 0;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.sm};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};

  @media (max-width: 480px) {
    gap: ${theme.spacing.md};
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  gap: ${theme.spacing.md};

  @media (max-width: 480px) {
    flex-direction: column;
    gap: ${theme.spacing.md};
  }
`;

const Label = styled.label`
  color: ${theme.colors.primary};
  font-weight: bold;
`;

const Input = styled.input`
  padding: ${theme.spacing.md};
  border: 1px solid ${theme.colors.gray};
  border-radius: ${theme.borderRadius.small};
  font-size: ${theme.fontSize.medium};
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 480px) {
    padding: ${theme.spacing.sm};
    font-size: ${theme.fontSize.small};
  }

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
  }
`;

const PayButton = styled.button`
  width: 100%;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;
  padding: ${theme.spacing.md};
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  font-size: ${theme.fontSize.medium};
  margin-top: ${theme.spacing.md};

  @media (max-width: 480px) {
    padding: ${theme.spacing.sm};
    font-size: ${theme.fontSize.small};
  }

  &:hover {
    background: ${theme.colors.primary}dd;
  }
`;

const ProductName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: ${theme.fontSize.medium};

  @media (max-width: 480px) {
    font-size: ${theme.fontSize.small};
  }
`;

const SizeInfo = styled.span`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.gray};

  @media (max-width: 480px) {
    font-size: ${theme.fontSize.xsmall};
  }
`;

const ProductPrice = styled.p`
  font-weight: bold;
  color: ${theme.colors.primary};
  font-size: ${theme.fontSize.medium};

  @media (max-width: 480px) {
    font-size: ${theme.fontSize.small};
  }
`;

export default Payment;
