import { createContext, useState, useContext } from "react";

const DEMO_USER = {
  id: 1,
  phoneNumber: "1234567890",
  password: "123",
  firstName: "Yiğit",
  lastName: "Demo",
};

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (phoneNumber, password) => {
    if (
      phoneNumber === DEMO_USER.phoneNumber &&
      password === DEMO_USER.password
    ) {
      const { password, ...userData } = DEMO_USER;
      setUser(userData);
      return true;
    }
    return false;
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
