import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useCart } from "../../context/CartContext";
import HotFilterCoffee from "../../assets/icons/HotFilterCoffee";
import ColdFilterCoffee from "../../assets/icons/ColdFilterCoffee";
import HotMilkyCoffee from "../../assets/icons/HotMilkyCoffee";
import ColdMilkyCoffee from "../../assets/icons/ColdMilkyCoffee";
import { SmallCup, LargeCup } from "../../components/icons/CupSize";

const products = {
  1: {
    id: 1,
    name: "Sıcak Filtre Kahve",
    price: 25,
    icon: HotFilterCoffee,
    sizes: {
      small: { name: "Küçük", price: 0 },
      large: { name: "Büyük", price: 5 },
    },
  },
  2: {
    id: 2,
    name: "Soğuk Filtre Kahve",
    price: 25,
    icon: ColdFilterCoffee,
    sizes: {
      small: { name: "Küçük", price: 0 },
      large: { name: "Büyük", price: 5 },
    },
  },
  3: {
    id: 3,
    name: "Sıcak Sütlü Filtre Kahve",
    price: 25,
    icon: HotMilkyCoffee,
    sizes: {
      small: { name: "Küçük", price: 0 },
      large: { name: "Büyük", price: 5 },
    },
  },
  4: {
    id: 4,
    name: "Soğuk Sütlü Filtre Kahve",
    price: 25,
    icon: ColdMilkyCoffee,
    sizes: {
      small: { name: "Küçük", price: 0 },
      large: { name: "Büyük", price: 5 },
    },
  },
};

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [selectedSize, setSelectedSize] = useState("small");
  const [showAddedMessage, setShowAddedMessage] = useState(false);

  const product = products[id];

  if (!product) {
    return <div>Ürün bulunamadı</div>;
  }

  const finalPrice = product.price + product.sizes[selectedSize].price;

  const handleAddToCart = () => {
    const cartItem = {
      ...product,
      selectedSize,
      finalPrice,
    };
    addToCart(cartItem);
    setShowAddedMessage(true);
    setTimeout(() => setShowAddedMessage(false), 2000);
  };

  const handleBuyNow = () => {
    const { icon, ...productWithoutIcon } = product;
    navigate(`/payment`, {
      state: {
        product: {
          ...productWithoutIcon,
          selectedSize,
          finalPrice,
        },
      },
    });
  };

  return (
    <Container>
      <ProductHeader>
        <BackButton onClick={() => navigate(-1)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
        </BackButton>
        <HeaderTitle>{product.name}</HeaderTitle>
      </ProductHeader>

      <ProductContent>
        <ContentCard>
          <SizeSelector>
            <SectionTitle>Boyut Seçimi</SectionTitle>
            <SizeOptions>
              {Object.entries(product.sizes).map(([size, details]) => (
                <SizeOption
                  key={size}
                  selected={selectedSize === size}
                  onClick={() => setSelectedSize(size)}
                >
                  <SizeIconWrapper>
                    {size === "small" ? <SmallCup /> : <LargeCup />}
                  </SizeIconWrapper>
                  <SizeDetails>
                    <SizeInfo>
                      <SizeName style={{ color: "#333" }}>
                        {details.name}
                      </SizeName>
                      <SizeDescription style={{ color: "#666" }}>
                        {size === "small"
                          ? "Standart Boy"
                          : "Daha Büyük Porsiyon"}
                      </SizeDescription>
                    </SizeInfo>
                    <PriceInfo>
                      {details.price > 0 ? (
                        <>
                          <BasePrice>{product.price} TL</BasePrice>
                          <TotalPrice>
                            {product.price + details.price} TL
                          </TotalPrice>
                        </>
                      ) : (
                        <TotalPrice>{product.price} TL</TotalPrice>
                      )}
                    </PriceInfo>
                  </SizeDetails>
                </SizeOption>
              ))}
            </SizeOptions>
          </SizeSelector>

          {/* <DescriptionSection>
            <SectionTitle>Kahveniz Hakkında</SectionTitle>
            <Description>
              Özenle seçilmiş kahve çekirdeklerinden hazırlanan filtre kahvemiz,
              zengin aroması ve dengeli tadıyla günün her saati keyifle
              içebileceğiniz bir seçenek sunuyor.
            </Description>
          </DescriptionSection> */}

          <ButtonGroup>
            <AddToCartButton onClick={handleAddToCart}>
              <CartIcon>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <circle cx="9" cy="21" r="1" />
                  <circle cx="20" cy="21" r="1" />
                  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                </svg>
              </CartIcon>
              Sepete Ekle
            </AddToCartButton>
            <BuyNowButton onClick={handleBuyNow}>Hemen Satın Al</BuyNowButton>
          </ButtonGroup>
        </ContentCard>
      </ProductContent>

      {showAddedMessage && (
        <AddedMessage>
          <span>✓</span> Ürün sepete eklendi
        </AddedMessage>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  height: 100vh;
  background: ${theme.colors.background};
  position: relative;
  overflow: hidden;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.lg};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const HeroSection = styled.div`
  padding-top: 60px;
  background: linear-gradient(
    to bottom,
    ${theme.colors.primary}15,
    ${theme.colors.background}
  );
`;

const ProductImage = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const IconWrapper = styled.div`
  width: 180px;
  height: 180px;
  color: ${theme.colors.primary};
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const PriceTag = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: ${theme.colors.primary};
  color: white;
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  border-radius: ${theme.borderRadius.large};
  font-size: ${theme.fontSize.xlarge};
  font-weight: bold;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 4px;

  .currency {
    font-size: ${theme.fontSize.large};
    opacity: 0.8;
  }

  .amount {
    font-weight: 800;
  }
`;

const ProductContent = styled.div`
  padding: ${theme.spacing.xl};
  padding-top: 80px;
  height: 100vh;
  overflow-y: auto;
`;

const ContentCard = styled.div`
  background: white;
  border-radius: ${theme.borderRadius.large};
  padding: ${theme.spacing.xl};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: ${theme.spacing.xl};
`;

const SectionTitle = styled.h3`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.lg};
  font-size: ${theme.fontSize.large};
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background: ${theme.colors.primary};
    border-radius: 2px;
  }
`;

const SizeOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.md} 0;
`;

const SizeOption = styled.div`
  padding: ${theme.spacing.lg};
  border: 2px solid
    ${({ selected }) =>
      selected ? theme.colors.primary : theme.colors.gray + "15"};
  border-radius: ${theme.borderRadius.large};
  cursor: pointer;
  background: ${({ selected }) =>
    selected ? `${theme.colors.primary}08` : "white"};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: ${({ selected }) =>
      selected ? theme.colors.primary : "transparent"};
    transition: all 0.3s ease;
  }

  &:hover {
    border-color: ${theme.colors.primary};
    transform: translateX(4px);
    background: ${({ selected }) =>
      selected ? `${theme.colors.primary}12` : `${theme.colors.primary}05`};
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${theme.spacing.lg};
  margin-top: ${theme.spacing.xl};
  padding-top: ${theme.spacing.lg};
  border-top: 1px solid ${theme.colors.gray}30;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.sm};
  text-align: center;
`;

const FeatureIcon = styled.span`
  font-size: 24px;
`;

const FeatureText = styled.span`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.gray};
`;

const BackButton = styled.button`
  background: none;
  border: none;
  padding: ${theme.spacing.sm};
  cursor: pointer;
  color: ${theme.colors.primary};
  transition: transform 0.2s;

  &:hover {
    transform: translateX(-2px);
  }
`;

const HeaderTitle = styled.h1`
  flex: 1;
  text-align: center;
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.primary};
  margin: 0;
  margin-right: ${theme.spacing.xl};
  font-weight: 600;
`;

const Description = styled.p`
  color: ${theme.colors.black};
  line-height: 1.8;
  margin-bottom: ${theme.spacing.xl};
  font-size: ${theme.fontSize.medium};
  letter-spacing: 0.3px;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.lg};
  background: rgba(255, 255, 255, 0.95);
  margin-top: ${theme.spacing.xl};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.medium};
`;

const BaseButton = styled.button`
  padding: ${theme.spacing.md};
  border-radius: ${theme.borderRadius.medium};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
`;

const AddToCartButton = styled(BaseButton)`
  background: ${theme.colors.secondary};
  color: ${theme.colors.primary};
  border: 2px solid ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.sm};

  &:hover {
    background: ${theme.colors.primary}10;
  }
`;

const BuyNowButton = styled(BaseButton)`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;

  &:hover {
    opacity: 0.9;
  }
`;

const CartIcon = styled.span`
  display: flex;
  align-items: center;
`;

const AddedMessage = styled.div`
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background: ${theme.colors.success};
  color: white;
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s ease-out;

  span {
    font-weight: bold;
  }

  @keyframes slideUp {
    from {
      transform: translate(-50%, 100%);
      opacity: 0;
    }
    to {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
`;

const SizeSelector = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const SizeIconWrapper = styled.div`
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.background};
  border-radius: ${theme.borderRadius.medium};
  margin-right: ${theme.spacing.md};
  padding: ${theme.spacing.sm};

  ${({ selected }) =>
    selected &&
    `
    background: ${theme.colors.primary}15;
  `}

  transition: all 0.2s ease;

  &:hover {
    background: ${theme.colors.primary}10;
    transform: scale(1.05);
  }
`;

const SizeDetails = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SizeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SizeDescription = styled.span`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.gray};
  font-weight: 500;
  opacity: 0.9;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;

const BasePrice = styled.span`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.gray};
  text-decoration: line-through;
`;

const TotalPrice = styled.span`
  color: ${theme.colors.primary};
  font-weight: 700;
  font-size: ${theme.fontSize.medium};
`;

const SizeName = styled.span`
  font-weight: 700;
  font-size: ${theme.fontSize.medium};
  color: ${theme.colors.primary};
  letter-spacing: -0.3px;
`;

const DescriptionSection = styled.div`
  margin-top: ${theme.spacing.xl};
  padding: ${theme.spacing.xl};
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.primary}20;
`;

export default ProductDetail;
