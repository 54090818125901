import { useNavigate } from "react-router-dom";
import { useCoffeePoints } from "../../context/CoffeePointsContext";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import HotFilterCoffee from "../../assets/icons/HotFilterCoffee";
import ColdFilterCoffee from "../../assets/icons/ColdFilterCoffee";
import HotMilkyCoffee from "../../assets/icons/HotMilkyCoffee";
import ColdMilkyCoffee from "../../assets/icons/ColdMilkyCoffee";
import CoffeePoint from "../common/CoffeePoint";
import LargeCoffeeIcon from "../common/LargeCoffeeIcon";

const products = [
  {
    id: 1,
    name: "Sıcak Filtre Kahve",
    price: 25,
    icon: HotFilterCoffee,
  },
  {
    id: 2,
    name: "Soğuk Filtre Kahve",
    price: 25,
    icon: ColdFilterCoffee,
  },
  {
    id: 3,
    name: "Sıcak Sütlü Filtre Kahve",
    price: 25,
    icon: HotMilkyCoffee,
  },
  {
    id: 4,
    name: "Soğuk Sütlü Filtre Kahve",
    price: 25,
    icon: ColdMilkyCoffee,
  },
];

const Home = () => {
  const navigate = useNavigate();
  const { coffeePoints } = useCoffeePoints();
  const fillPercentage = (coffeePoints / 5) * 100;

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <Container>
      <Title>Kahve Çeşitlerimiz</Title>
      <ProductGrid>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            onClick={() => handleProductClick(product.id)}
          >
            <IconWrapper>
              <product.icon />
            </IconWrapper>
            <ProductName>{product.name}</ProductName>
            <ProductPrice>{product.price} TL</ProductPrice>
            <BuyButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/product/${product.id}`);
              }}
            >
              Sipariş Ver
            </BuyButton>
          </ProductCard>
        ))}
      </ProductGrid>
      <PointsSection>
        <PointsTitle>Kahve Puanlarım</PointsTitle>
        <PointsContainer>
          <CoffeePointsContainer>
            {[...Array(5)].map((_, index) => (
              <CoffeePoint key={index} filled={index < coffeePoints} />
            ))}
          </CoffeePointsContainer>
          <LargeCoffeeWrapper>
            <LargeCoffeeIcon fillPercentage={fillPercentage} />
          </LargeCoffeeWrapper>
        </PointsContainer>
        <PointsInfo>5 kahve puanı topla, 1 kahve kazan!</PointsInfo>
      </PointsSection>
    </Container>
  );
};

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  justify-content: center;
`;

const LargeCoffeeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CoffeePointsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    width: 28px;
    height: 28px;
  }
`;

const Container = styled.div`
  padding: 16px;
  padding-bottom: 180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background: ${theme.colors.gray};
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  width: 100%;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 0;
  margin: 0 auto;
  width: 100%;
`;

const ProductCard = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    transform: translateY(-2px);
  }
`;

const IconWrapper = styled.div`
  color: ${theme.colors.primary};
  font-size: 24px;
  text-align: center;
  margin: 8px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 36px;
    height: 36px;
  }
`;

const ProductName = styled.h3`
  color: ${theme.colors.primary};
  text-align: center;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1.2;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
`;

const ProductPrice = styled.p`
  color: ${theme.colors.black};
  text-align: center;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
`;

const BuyButton = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: none;
  border-radius: ${theme.borderRadius.small};
  padding: 6px;
  width: 100%;
  cursor: pointer;
  transition: background 0.2s;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:hover {
    background: ${theme.colors.primary}dd;
  }
`;

const PointsSection = styled.div`
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  background: ${theme.colors.white};
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const PointsTitle = styled.h3`
  color: ${theme.colors.primary};
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const PointsInfo = styled.p`
  color: ${theme.colors.black}99;
  font-size: 12px;
  margin: 0;
`;

export default Home;
