export const theme = {
  colors: {
    primary: "#6F4E37", // Kahverengi
    secondary: "#E8DCC4", // Bej
    white: "#FFFFFF",
    black: "#000000",
    gray: "#F5F5F5",
    error: "#FF0000",
    success: "#4CAF50",
    background: "#f5f5f5", // Açık gri bir arka plan rengi
  },
  fontSize: {
    small: "12px",
    medium: "16px",
    large: "20px",
    xlarge: "24px",
  },
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
  },
  borderRadius: {
    small: "4px",
    medium: "8px",
    large: "16px",
  },
};
