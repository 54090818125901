const HotFilterCoffee = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 5H4V11C4 14.3137 6.68629 17 10 17H12C15.3137 17 18 14.3137 18 11V5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M18 8H20C21.1046 8 22 8.89543 22 10V10C22 11.1046 21.1046 12 20 12H18"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M6 3V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16 3V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7 20H17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default HotFilterCoffee;
